<template>
  <!-- <div style="display: flex; align-items: center; flex-wrap: wrap"> -->
  <div class="CouponsII">
    <div class="lq-icon">
      <img :src="lqImg" alt="" v-if="data.lq == 2" />
    </div>
    <div class="folat">
      <div class="asd"></div>
    </div>

    <div class="j-right">
      <div>
        <el-image
          style="width: 90px; height: 90px; margin-right: 10px"
          :src="data.goods.goods_image"
          fit="fit"
        ></el-image>
      </div>
      <div
        style="
          display: flex;
          justify-content: space-between;
          flex-direction: column;
        "
      >
        <div class="row-hidden" style="font-size: 14px">
          {{ data.goods.goods_name }}
        </div>
        <div style="font-size: 14px; color: #ee4d2d">
          RM{{ data.goods.goods_price }}
        </div>
      </div>
      <!-- <div style="font-size: 16px; font-weight: 600">50%折扣</div>
      <div style="font-size: 14px">最低消费RM100限为RM20</div>
      <div style="margin: 6px 0; transform: scale(0.9)">
        <span class="tags">指定商品</span>
      </div>
      <div class="seep">
        <div class="tiao"></div>
      </div>
      <div style="font-size: 12px; color: #ee4d2d">
        已使用78%,即将截止：剩下6小时
      </div> -->
    </div>
    <div class="line"></div>
    <div class="j-left">
      <div style="font-size: 14px; font-weight: 600">
        {{
          data.discount_coupon_type == 1
            ? "RM" + data.discount_amount
            : data.discount_amount + "%"
        }}折扣
      </div>
      <div style="font-size: 12px; margin: 6px 0 10px; color: #b0b0b0">
        最低消费RM{{ data.min_consume_amount }}
      </div>
      <div>
        <el-button @click="toStore" size="mini" v-if="data.lq == 2" plain
          >使用</el-button
        >
        <el-button @click="receive" size="mini" type="primary" v-else
          >领取</el-button
        >
      </div>
    </div>
  </div>
  <!-- </div> -->
</template>
<script>
import { couponReceive } from "@/api/buy.js";
export default {
  name: "CouponsII",
  props: ["data"],
  data() {
    return {
      lqImg: require("@/assets/img/marketing/receive.png"),
    };
  },
  methods: {
    receive() {
      couponReceive({
        keyword: this.$props.data.id,
        type: 1,
      }).then((res) => {
        if (res.code == 1) {
          this.$props.data.lq = 2;
          this.$forceUpdate();
          this.$message.success(res.message);
        }
      });
    },
    toStore() {
      window.scrollTo(0, 0);
      this.$router.push({
        path: "storeHome",
        query: {
          shop_id: this.$props.data.shop_id,
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.CouponsII {
  width: 460px;
  height: 130px;
  border: 1px solid #eee;
  box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  border-left: 1px solid rgba(250, 250, 250, 0.01);
  display: flex;
  align-items: center;
  border-radius: 2px;
  margin-right: 20px;
  margin-bottom: 20px;
  padding-left: 20px;
  .lq-icon {
    position: absolute;
    right: 2px;
    top: 0;

    img {
      width: 60px;
    }
  }
  .folat {
    content: "";
    width: 6px;
    height: 100%;
    position: absolute;
    left: -1px;
    top: 0;
    background: radial-gradient(
      circle at 0 6px,
      transparent 0,
      #fff 5px,
      #eee 0,
      #eee 6px,
      #fff 0
    );
    background-size: 6px 14px;
    background-repeat: repeat-y;
    .asd {
      background: repeating-linear-gradient(
        #eee,
        #eee 1.9px,
        transparent 0,
        transparent 12px
      );
      background-size: 6px 14px;
      position: absolute;
      left: 0;
      top: -1px;
      height: 100%;
      width: 1px;
    }
  }
  .j-left {
    width: 130px;
    height: 100%;
    text-align: center;
    padding: 30px 0 20px;
  }
  .j-right {
    width: 300px;
    display: flex;

    .tags {
      border: 1px solid #ee4d2d;
      padding: 2px;
      font-size: 12px;
      border-radius: 2px;
      color: #ee4d2d;
      margin-left: -10px;
    }
    .seep {
      width: 100%;
      height: 4px;
      border-radius: 4px;
      background: #e8e8e8;
      margin-bottom: 4px;
      .tiao {
        width: 70%;
        height: 100%;
        background: linear-gradient(to right, #eb1d16, #feac00);
        border-radius: 4px;
      }
    }
  }
  .line {
    height: 130px;
    width: 1px;
    margin-left: 10px;
    background-image: linear-gradient(
      to bottom,
      #eee 0%,
      #eee 50%,
      transparent 0%
    );
    background-size: 1px 8px;
    background-repeat: repeat-y;
  }
}
</style>