<template>
  <!-- <div style="display: flex; align-items: center; flex-wrap: wrap"> -->
  <div class="CouponsI">
    <div class="ban" v-if="type == 'end'"></div>
    <div class="overdue" v-if="type == 'end' && data.state == 3">
      <div>已过期</div>
      <div class="sanjiao"></div>
    </div>
    <div class="been-used" v-if="type == 'end' && data.state == 2">
      <img :src="ysyImg" alt="" />
    </div>
    <div v-if="type != 'end'" class="btn" @click="ToStore(data.shop_id)">
      使用<i class="el-icon-arrow-right"></i>
    </div>
    <div class="folat">
      <div class="asd"></div>
    </div>
    <div class="j-left">
      <div>
        <el-avatar
          style="background: #fff; border: 1px solid #eee"
          :size="50"
          :src="data.shop.shop_avatar"
        ></el-avatar>
      </div>
      <div style="font-size: 12px; margin-top: 6px">
        {{ data.shop.shop_name }}
      </div>
    </div>
    <div class="line"></div>
    <div class="j-right">
      <div style="font-size: 16px; font-weight: 600">
        {{ data.user_type == 1 ? "RM" + data.price : data.discount + "%" }}折扣
      </div>
      <div style="font-size: 14px; margin-bottom: 10px">
        最低消费RM{{ data.min_discount }}
        {{ data.max_discount == 0 ? "" : "上限为RM" + data.max_discount }}
      </div>
      <div
        v-if="data.range_type == 2"
        style="margin: 6px 0; transform: scale(0.9)"
      >
        <span class="tags">指定商品</span>
      </div>
      <div class="seep" v-if="data.already_num != 0">
        <div class="tiao" :style="{ width: `${data.already_num}%` }"></div>
      </div>
      <div
        style="font-size: 12px; color: #ee4d2d"
        v-if="day > data.end_time - time && type != 'end'"
      >
        {{
          data.already_num != 0
            ? "已使用" + data.already_num.toFixed(2) + "%,"
            : ""
        }}
        {{ day > data.end_time - time ? "  即将截止：剩下" + syTime : "" }}
      </div>
      <div v-else style="font-size: 12px; color: #b0b0b0">
        有效期：{{ jsTime }}
      </div>
    </div>
  </div>
  <!-- </div> -->
</template>
<script>
export default {
  name: "CouponsI",
  props: ["type", "data"],
  data() {
    return {
      circleUrl: "",
      time: 0,
      day: 24 * 60 * 60,
      syTime: 0,
      jsTime: 0,
      ysyImg: require("@/assets/img/marketing/used.png"),
    };
  },
  created() {
    this.time = parseInt(Date.now() / 1000);
  },
  mounted() {
    let date = this.$props.data.end_time;
    let Edate = new Date(date * 1000);
    this.syTime = parseInt((date - this.time) / 3600) + "小时";
    if (this.syTime == "0小时") {
      this.syTime = parseInt((date - this.time) / 60) + "分钟";
      if (this.syTime == "0分钟") {
        this.syTime = parseInt((date - this.time) / 1) + "秒";
      }
    }

    this.jsTime =
      Edate.getMonth() + 1 + "." + Edate.getDate() + "." + Edate.getFullYear();
  },
  methods: {
    ToStore(id) {
      window.scrollTo(0, 0);
      this.$router.push({
        path: "storeHome",
        query: {
          shop_id: id,
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.CouponsI {
  width: 460px;
  height: 130px;
  border: 1px solid #eee;
  box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  border-left: 1px solid rgba(250, 250, 250, 0.01);
  display: flex;
  align-items: center;
  border-radius: 2px;
  margin-right: 20px;
  margin-bottom: 20px;
  .btn {
    position: absolute;
    right: 10px;
    top: 12px;
    font-size: 14px;
    color: #ee4d2d;
    cursor: pointer;
  }
  .folat {
    content: "";
    width: 6px;
    height: 100%;
    position: absolute;
    left: -1px;
    top: 0;
    background: radial-gradient(
      circle at 0 6px,
      transparent 0,
      #fff 5px,
      #eee 0,
      #eee 6px,
      #fff 0
    );
    background-size: 6px 14px;
    background-repeat: repeat-y;
    .asd {
      background: repeating-linear-gradient(
        #eee,
        #eee 1.9px,
        transparent 0,
        transparent 12px
      );
      background-size: 6px 14px;
      position: absolute;
      left: 0;
      top: -1px;
      height: 100%;
      width: 1px;
    }
  }
  .j-left {
    width: 130px;
    height: 100%;
    text-align: center;
    padding: 20px;
  }
  .j-right {
    width: 26 0px;
    .tags {
      border: 1px solid #ee4d2d;
      padding: 2px;
      font-size: 12px;
      border-radius: 2px;
      color: #ee4d2d;
      margin-left: -10px;
    }
    .seep {
      width: 100%;
      height: 4px;
      border-radius: 4px;
      background: #e8e8e8;
      margin-bottom: 4px;
      .tiao {
        height: 100%;
        background: linear-gradient(to right, #eb1d16, #feac00);
        border-radius: 4px;
      }
    }
  }
  .line {
    height: 130px;
    width: 1px;
    margin-right: 10px;
    background-image: linear-gradient(
      to bottom,
      #eee 0%,
      #eee 50%,
      transparent 0%
    );
    background-size: 1px 8px;
    background-repeat: repeat-y;
  }
  .ban {
    background: #fff;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 22;
    opacity: 0.6;
  }
  .overdue {
    position: absolute;
    width: 42px;
    height: 20px;
    background: #9f9f9f;
    color: #fff;
    font-size: 12px;
    left: -6px;
    top: 4px;
    text-align: center;
    line-height: 20px;
    z-index: 33;
    border-radius: 2px;
    .sanjiao {
      width: 6px;
      height: 4px;
      position: absolute;
      bottom: -4px;
      left: 4px;
      border: 4px solid transparent;
      border-right-color: #757575;
      transform: rotate(134deg);
    }
  }
  .been-used {
    position: absolute;
    right: 40px;
    img {
      width: 80px;
    }
  }
}
</style>