<template>
  <div class="buyCoupons">
    <div class="card">
      <div class="title">
        <div>我的优惠券</div>
        <div
          @click="toHs"
          style="font-size: 14px; color: #ee4d2d; cursor: pointer"
        >
          查看优惠券历史记录
        </div>
      </div>
      <div class="search">
        <div>添加优惠券</div>
        <div style="margin: 0 10px">
          <el-input
            clearable
            style="width: 400px"
            v-model="input"
            placeholder="Please enter voucher code"
          ></el-input>
        </div>
        <div>
          <el-button @click="handleLq" type="primary" v-if="input"
            >兑换</el-button
          >
          <el-button disabled type="info" v-else>兑换</el-button>
        </div>
      </div>
      <div class="list">
        <div class="list-head">
          <div
            class="btn"
            :class="index == 0 ? 'active' : ''"
            @click="handleClick(0)"
          >
            全部
          </div>
          <div
            class="btn"
            :class="index == 1 ? 'active' : ''"
            @click="handleClick(1)"
          >
            即将到期
          </div>
        </div>
        <div class="list-content" v-if="listData.list && listData.list != 0">
          <CouponsI
            v-for="item in listData.list"
            :key="item.id"
            :data="item"
          ></CouponsI>
        </div>
        <div
          v-else
          style="
            line-height: 100px;
            font-size: 14px;
            color: #b0b0b0;
            text-align: center;
          "
        >
          暂无
        </div>
      </div>
    </div>
    <div class="card">
      <div class="title">推荐的优惠券</div>
      <div
        v-if="listTjData && listTjData.length != 0"
        style="display: flex; align-items: center; flex-wrap: wrap"
      >
        <CouponsII
          v-for="item in listTjData"
          :key="item.id"
          :data="item"
        ></CouponsII>
      </div>
      <div
        v-else
        style="
          line-height: 100px;
          font-size: 14px;
          color: #b0b0b0;
          text-align: center;
        "
      >
        暂无
      </div>
    </div>
  </div>
</template>
<script>
import CouponsI from "@/components/marketing/CouponsI";
import CouponsII from "@/components/marketing/CouponsII";
import { getMyList, couponReceive, recommendCoupons } from "@/api/buy.js";
export default {
  components: {
    CouponsI,
    CouponsII,
  },
  data() {
    return {
      input: "",
      index: 0,
      listData: "",
      listTjData: "",
      page: 1,
      page_size: 100,
    };
  },
  created() {
    this.getList();
    this.getTjList();
  },
  methods: {
    getList() {
      getMyList({
        state: this.index,
        page: this.page,
        page_size: this.page_size,
      }).then((res) => {
        if (res.code == 1) {
          this.listData = res.data;
          // this.listData.list[0].already_num = 10;
        }
      });
    },
    getTjList() {
      recommendCoupons().then((res) => {
        if (res.code == 1) {
          this.listTjData = res.data;
          this.listTjData.forEach((item) => {
            item.lq = 1;
          });
        }
      });
    },
    handleClick(val) {
      this.index = val;
      this.getList();
    },
    toHs() {
      this.$router.push("buyCouponsHs");
    },
    handleLq() {
      couponReceive({
        keyword: this.input,
        type: 2,
      }).then((res) => {
        if (res.code == 1) {
     
          this.$message.success(res.message);
          this.getList();
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.buyCoupons {
  padding: 20px;
  .card {
    background: #fff;
    border-radius: 2px;
    box-shadow: 0 1px 1px #eee;
    padding: 20px;
    width: 1000px;
    margin-bottom: 30px;
    .title {
      font-size: 18px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
    }
    .search {
      background: #f7f7f7;
      padding: 40px 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .list {
      margin-top: 20px;
      .list-head {
        background: #f7f7f7;
        padding: 14px 20px;
        display: flex;
        align-items: center;
        font-size: 14px;
        .btn {
          padding: 0 40px;
          cursor: pointer;
        }
        .active {
          color: #ee4d2d;
        }
      }
      .list-content {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding: 20px 0;
      }
    }
  }
}
</style>